#header {
    line-height: normal;
    border-bottom: 1px solid var(--header-border-color);

    @include tablet() {
        border-bottom: 0;
    }

    & > .inside {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include menu() {
            display: block;
        }
    }

    .header-logo {
        padding: 20px 30px;

        @include menu() {
            padding: 0;
            display: flex;
            justify-content: center;
            padding: 31px var(--main-padding);
        }
    }

    .menu-button {
        width: 48px;
        height: 48px;
        background-image: var(--icon-burger);
        background-size: 22px auto;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        cursor: pointer;
        border: 0;
        padding: 0;
        margin-left: 0;
        margin-top: -8px;
        margin-right: 7px;
        margin-bottom: -8px;
        text-indent: 100px;
        overflow: hidden;

        @include menu() {
            display: none;
        }
    }
}
