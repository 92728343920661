.content-swiper {
    --swiper-navigation-top-offset: calc(50% - 20px);

    .swiper {
        &-slide {
            img {
                width: 100%;
            }
        }

        &-button {
            &-prev,
            &-next {
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 50%;
                border: 0;
                padding: 0;
                margin: 0;
                text-indent: 100px;
                white-space: nowrap;
                overflow: hidden;
                width: 40px;
                height: 40px;
                transition: opacity 0.2s, background-color 0.2s;

                &:hover {
                    background-color: rgba(0, 0, 0, 1);
                }

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    mask-repeat: no-repeat;
                    mask-size: auto 24px;
                    mask-position: center;
                    display: block;
                    background-color: white;
                }
            }

            &-prev {
                left: 20px;

                &:after {
                    mask-image: var(--icon-caret-left);
                }
            }

            &-next {
                right: 20px;

                &:after {
                    mask-image: var(--icon-caret-right);
                }
            }
        }
    }
}
