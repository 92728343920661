.content-image,
.content-gallery,
.content-text {
    figure {
        overflow: hidden;

        a[data-lightbox] {
            img {
                transition: transform 1s, opacity 1s;
                opacity: 1;

                &:hover {
                    transform: scale(1.1);
                    opacity: 0.9;
                }
            }
        }
    }
}

.mod_cfg_instagram .item {
    a {
        display: block;
        overflow: hidden;

        img {
            transition: transform 1s, opacity 1s;
            opacity: 1;

            &:hover {
                transform: scale(1.1);
                opacity: 0.9;
            }
        }
    }
}
