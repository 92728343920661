.mod_cfg_instagram {
    margin: 2em 0;

    .items {
        display: grid;
        gap: 1em;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));

        img {
            width: 100%;
        }

        @include tablet() {
            grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        }
    }
}
