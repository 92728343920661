#footer {
    padding: 18px var(--main-padding);
    background-color: var(--footer-background-color);
    color: white;
    font-size: 12px;

    a {
        color: inherit;
        text-decoration: none;
    }

    & > .inside {
        max-width: var(--max-width);
        margin-left: auto;
        margin-right: auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .footer-copyright {
        text-align: center;
    }
}
