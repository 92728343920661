.btt {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: #333333;
    transition: background-color 0.4s;
    position: fixed;
    right: 75px;
    bottom: 0;
    width: 48px;
    height: 35px;
    text-indent: 100px;
    white-space: nowrap;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s, visibility 0.4s;
    display: none;

    @include tablet() {
        display: block;
    }

    &.show {
        opacity: 1;
        visibility: visible;
    }

    &:after {
        position: absolute;
        left: 0;
        top: 1px;
        right: 0;
        bottom: -1px;
        margin: auto;
        content: '';
        display: block;
        width: 22px;
        height: 22px;
        mask-image: var(--icon-chevron-up);
        mask-position: center;
        mask-size: 100% auto;
        mask-repeat: no-repeat;
        background-color: white;
    }

    &:hover {
        background-color: #0065b2;
    }
}
