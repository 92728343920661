.content-element-group {
    &.group--wedding-list {
        margin: 40px 0;
        display: flex;
        gap: 40px;
        justify-content: center;
        flex-wrap: wrap;

        @include tablet() {
            margin: vwmax(80px) 0;
            gap: vwmax(80px);
        }
    }
}
