/**
 * Basic styling, basic font size, CSS variables etc.
 */
:root {
    --icon-burger: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 6l16 0" /><path d="M4 12l16 0" /><path d="M4 18l16 0" /></svg>');
    --icon-chevron-left: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 6l-6 6l6 6" /></svg>');
    --icon-chevron-right: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>');
    --icon-caret-left: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M13.883 5.007l.058 -.005h.118l.058 .005l.06 .009l.052 .01l.108 .032l.067 .027l.132 .07l.09 .065l.081 .073l.083 .094l.054 .077l.054 .096l.017 .036l.027 .067l.032 .108l.01 .053l.01 .06l.004 .057l.002 .059v12c0 .852 -.986 1.297 -1.623 .783l-.084 -.076l-6 -6a1 1 0 0 1 -.083 -1.32l.083 -.094l6 -6l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01z" /></svg>');
    --icon-caret-right: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" /></svg>');
    --icon-magnify: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" /><path d="M7 10l6 0" /><path d="M10 7l0 6" /><path d="M21 21l-6 -6" /></svg>');
    --icon-chevron-up: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 15l6 -6l6 6" /></svg>');

    --font-garamond: 'EB Garamond', serif;
    --font-ptsans: 'PT Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    --main-padding: 30px;
    --max-width: 1100px;

    --link-color: #2d5288;
    --link-hover-color: #0065b2;

    --menu-border-color: #e5e5e5;
    --menu-background-color: #f7f7f7;
    --menu-gap: 25px;
    --menu-height: 40px;
    --menu-active-color: var(--link-color);

    --mobile-menu-background-color: #f9f9f9;
    --mobile-menu-hover-color: #f6f6f6;
    --mobile-menu-border-color: #dadada;
    --mobile-menu-text-color: #333333;

    --sub-menu-background-hover-color: #f2f2f2;
    --sub-menu-border-color: #dcdadb;

    --text-color: #212121;
    --text-size: #{$text-size-mobile};
    --text-line-height: 1.5;

    --text-h1-color: #333333;
    --text-h2-color: #333333;

    --footer-background-color: #282a2b;

    // Default for dynamic scrollbar width
    --scrollbar-width: 0px;

    // Deafult for dynamic header height
    --header-height: 72px;
    --header-border-color: #e5e5e5;

    @include tablet() {
        --text-size: #{$text-size-desktop};
    }

    @include desktop() {
        --menu-gap: 45px;
    }
}

html {
    scroll-behavior: smooth;
}

body, html {
    height: 100%;
}

body { 
    overflow: hidden;
    overflow-y: scroll;
}

figure {
    display: block;
    max-width: 100%;
    margin: 0;
    box-sizing: border-box;
}

main {
    display: block;
}

img {
    border: 0;
}

video {
    max-width: 100%;
    height: auto;
}

figure img {
    height: auto;
    max-width: 100%;
    display: block;
    margin: 0;
}

figure figcaption {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
}

iframe {
    border:0;
}

.invisible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.wow {
    visibility: hidden;
}

.inside {
    position:relative;
}

.content-player video {
    width: 100%;
    height: auto;
}

/* default 16:9 aspect ratio */
.content-youtube, .content-vimeo {
    .video-wrapper,
    .video_container {
        position: relative;
        padding-bottom:56.25%;
        height:0;
    }

    .video-wrapper > *,
    .video_container > :not(.responsive):not(.caption),
    .video_container > .responsive > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        z-index: 6;
    }
}
