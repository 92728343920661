.glightbox-clean {
    .gslide-description {
        background-color: black;
        color: white;
        font-size: 15px;
        font-family: var(--font-ptsans);
    
        p:first-child {
            margin-top: 0;
        }
    
        p:last-child {
            margin-bottom: 0;
        }
    }
    
    .gslide-title {
        margin: 0;
        color: inherit;
        font-family: inherit;
    }
}
