/**
 * Basic typography defintions
 */
html { 
    font-family: var(--font-garamond);
    font-size: var(--text-size);
    line-height: var(--text-line-height);
    color: var(--text-color);
    letter-spacing: 0.5px;
}

a {
    color: var(--link-color);
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
        color: var(--link-hover-color);
    }

    &:focus-visible {
        outline-style: solid;
        outline-width: 2px;
        outline-color: var(--link-hover-color);
    }
}

b, strong {
    font-weight: 500;
}

h1, h2, h3, h4, h5, h6 {
    a {
        &, &:hover {
            color: inherit;
            text-decoration: inherit;
        }
    }
}

h1 {
    @include h1();
}

h2 {
    @include h2();
}

h3 {
    @include h3();
}

h4 {
    @include h4();
}

h5 {
    @include h5();
}

h6 {
    @include h6();
}

ul,
ol {
    margin: 1em 0;
}

/* headline styles */
h1.headline--h1,
h2.headline--h1,
h3.headline--h1,
.headline--h1 h1,
.headline--h1 h2,
.headline--h1 h3 {
    @include h1();
}

h1.headline--h2,
h2.headline--h2,
h3.headline--h2,
.headline--h2 h1,
.headline--h2 h2,
.headline--h2 h3 {
    @include h2();
}

h1.headline--h3,
h2.headline--h3,
h3.headline--h3,
.headline--h3 h1,
.headline--h3 h2,
.headline--h3 h3 {
    @include h3();
}

/* margins */
@mixin margin-generator($type, $margin) {
    @if $type == '' {
        .margin--#{$margin} {
            @include margin-type('top', $margin, true);
            @include margin-type('bottom', $margin, true);
        }
    } @else {
        .margin--#{$type}--#{$margin} {
            @include margin-type($type, $margin, true);
        }
    }
}

@mixin margin($margin) {
    @include margin-generator('', $margin);
    @include margin-generator('top', $margin);
    @include margin-generator('bottom', $margin);
}

@for $i from 0 through 8 {
    @include margin($i);
}

.image--center figure img {
    margin: auto;
}

.image--left figure img {
    margin-left: 0;
    margin-right: auto;
}

.image--right figure img {
    margin-right: 0;
    margin-left: auto;
}

.text--center {
    text-align: center;
}

.text--left {
    text-align: left;
}

.text--right {
    text-align: right;
}
