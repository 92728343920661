@import "~normalize.css/normalize.css";
@import '~glightbox/dist/css/glightbox.min.css';

/* global settings */
@import './settings/_layout.scss';

/* functions and mixins */
@import './functions/_breakpoints.scss'; @import './functions/_grid.scss'; @import './functions/_mixins.scss'; @import './functions/_styles.scss';

/* base styles settings */
@import './base/_basic.scss'; @import './base/_fonts.scss'; @import './base/_typography.scss';

/* styles of various components */
@import './components/_btt.scss'; @import './components/_cookiebar.scss'; @import './components/_glightbox.scss'; @import './components/_images.scss'; @import './components/_tinymce.scss'; @import './components/_turbo-progress-bar.scss';

/* section styles */
@import './sections/_container.scss'; @import './sections/_footer.scss'; @import './sections/_header.scss'; @import './sections/_wrapper.scss';

/* module styles */
@import './modules/_mod_article.scss'; @import './modules/_mod_cfg_instagram.scss'; @import './modules/_mod_navigation--main.scss';

/* element styles */
@import './elements/_content-element-group.scss'; @import './elements/_content-grid.scss'; @import './elements/_content-headline.scss'; @import './elements/_content-image.scss'; @import './elements/_content-swiper.scss'; @import './elements/_content-text.scss';
