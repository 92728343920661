.mod_navigation.navigation--main {
    display: none;
    letter-spacing: normal;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    @include menu('max') {
        display: block;
        background-color: var(--mobile-menu-background-color);
        position: absolute;
        left: 0;
        top: var(--header-height);
        right: 0;
        z-index: 2;
        font-size: 12px;
        font-family: var(--font-ptsans);
        text-transform: uppercase;
        color: var(--mobile-menu-text-color);
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s;

        body.show-menu & {
            max-height: 100vh;
        }

        button.toggle-subitems {
            display: block;
            padding: 0;
            margin: 0;
            border: 0;
            background-color: var(--mobile-menu-text-color);
            mask-image: var(--icon-chevron-right);
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: 16px auto;
            width: 32px;
            height: 32px;
            position: absolute;
            right: 20px;
            top: 0;
            bottom: 0;
            margin: auto;
            cursor: pointer;
            transition: transform 0.2s;
        }

        li {
            border-top: 1px solid var(--mobile-menu-border-color);

            & > a,
            & > span,
            & > strong {
                font-weight: inherit;
                color: inherit;
                text-decoration: inherit;
                line-height: 2.9166;
                display: block;
                padding: 0 30px;
                transition: background-color 0.2s;
                position: relative;

                &.submenu {
                    padding-right: 64px;
                }

                &:hover,
                &.active,
                &.trail
                &:focus-visible {
                    background-color: var(--mobile-menu-hover-color);
                }

                &.submenu.show {
                    button.toggle-subitems {
                        transform: rotate(90deg);
                    }

                    & + ul.level_2 {
                        max-height: 100vh;
                    }
                }
            }
        }

        ul.level_1 {
            & > li {
                &:first-child {
                    border-top: 1px solid var(--header-border-color);
                }

                &:last-child {
                    border-bottom: 1px solid var(--mobile-menu-border-color);
                }
            }
        }

        ul.level_2 {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.2s;

            & > li {
                & > a,
                & > span,
                & > strong {
                    padding-left: 39px;

                    &:before {
                        content: '-\00a0';
                    }
                }
            }
        }
    }

    @include menu() {
        display: block;
        background-color: var(--menu-background-color);
        border-top: 1px solid var(--menu-border-color);
        border-bottom: 1px solid var(--menu-border-color);
        padding: 0 10px;
        font-size: 16px;

        button.toggle-subitems {
            display: none;
        }

        ul.level_1 {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: var(--menu-gap);
            row-gap: 0;
            flex-wrap: wrap;

            li {
                position: relative;

                & > a,
                & > span,
                & > strong {
                    display: flex;
                    align-items: center;
                    height: var(--menu-height);
                    font-weight: normal;
                    text-transform: uppercase;
                    border-top: 3px solid transparent;
                    box-sizing: border-box;
                    text-decoration: none;
                    transition: color 0.2s, border-color 0.2s, background-color 0.2s;
                    color: inherit;
                    white-space: nowrap;

                    &:hover,
                    &:focus-visible,
                    &.active,
                    &.trail {
                        border-color: var(--menu-active-color);
                        color: var(--menu-active-color);
                    }
                }

                &.submenu {
                    &:hover,
                    &:has(*:focus-visible) {
                        ul.level_2 {
                            opacity: 1;
                            visibility: visible;
                            pointer-events: all;
                        }
                    }
                }
            }
        }

        ul.level_2 {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: opacity 0.2s, visibility 0.2s;
            position: absolute;
            top: var(--menu-height);
            right: 0;
            z-index: 2;
            background-color: white;
            border-top: 3px solid var(--menu-active-color);
            min-width: 180px;
            max-width: 380px;
            box-sizing: border-box;
            box-shadow: 1px 1px 30px rgba(0,0,0,.06);

            @include breakpoint(1140px) {
                right: auto;
                left: 0;
            }

            & > li {
                border-bottom: 1px solid var(--sub-menu-border-color);

                & > a,
                & > span,
                & > strong {
                    border-top: 0;
                    padding: 0 20px;

                    &:hover,
                    &.active,
                    &:focus-visible {
                        background-color: var(--sub-menu-background-hover-color);
                        color: inherit;
                    }
                }
            }
        }
    }

    @include desktop() {
        padding: 0 var(--main-padding);
    }
}
