.content-text {
    figure {
        margin: 1rem 0;
    }

    .mod_article.article--full-width & {
        img {
            width: 100%;
        }
    }

    &.media--left,
    &.media--right {
        margin-bottom: 3rem;

        @include tablet() {
            display: flex;
            gap: 2.5em;
            margin-bottom: 1rem;

            .rte {
                & > *:first-child {
                    margin-top: 1rem;
                }
            }
        }
    }

    &.media--right {
        @include tablet() {
            figure {
                order: 99;
            }
        }
    }

    &.text--wedding-item {
        font-family: var(--font-ptsans);
        font-size: 16px;
        line-height: 1.3;
        border: 1px solid #dddddd;
        box-sizing: border-box;
        padding: 4px;
        flex-basis: 250px;

        .rte {
            margin: 0.7em 0.6em;
            margin-top: 0.8em;

            p:first-child {
                margin-top: 0;
            }

            p:last-child {
                margin-bottom: 0;
            }
        }

        figure {
            margin: 0;
            position: relative;

            &:before {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                pointer-events: none;
                content: '';
                display: block;
                opacity: 0;
                background-color: rgba(0, 0, 0, 0.6);
                transition: opacity 0.3s;
                z-index: 1;
            }

            &:after {
                width: 38px;
                height: 38px;
                mask-image: var(--icon-magnify);
                mask-repeat: no-repeat;
                mask-position: center;
                mask-size: 100% auto;
                background-color: white;
                opacity: 0;
                transition: opacity 0.3s;
                content: '';
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                z-index: 2;
                pointer-events: none;
            }

            &:hover {
                &:after,
                &:before {
                    opacity: 1;
                }
            }
        }
    }
}
