.mod_article {
    max-width: var(--max-width);
    margin-left: auto;
    margin-right: auto;
    padding: 0 var(--main-padding);

    &.article--full-width {
        max-width: none;
        padding: 0;
        position: relative;
    }

    &.article--792 {
        max-width: 792px;
    }

    &.article--912 {
        max-width: 912px;
        margin-left: auto;
        margin-right: auto;
    }

    &.article--1920 {
        max-width: 1920px;
    }
}
